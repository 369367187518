<template>
  <v-container>
    <div class="d-flex justify-center" :class="!$vuetify.breakpoint.xs ? 'flex-row' : 'flex-column text-center mt-10'"
      :style="!$vuetify.breakpoint.xs && 'padding: 50px 8px 0px 0px;'">
      <div style="padding-right:20px;padding-left:20px">
        <h1 style="color: #fff; font-weight:800" :style="{
          fontSize: !$vuetify.breakpoint.xs ? '4em' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '40px',
          margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
          maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '700px',
          textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
        }">
          <span id="animated-number" style="color:#fe5e02;  font-weight:800;">8,140,681</span><br />
          {{ $t('landing.phrase_0') }}<br />
          {{ $t('landing.phrase_1') }}<br />
          <div v-if="user === null" class="mt-10 d-flex"
            :class="$vuetify.breakpoint.xs && 'justify-center align-center text-center'">

            <v-row align="center" dense :justify="!$vuetify.breakpoint.lg && 'center'">
              <v-col cols=" 4" xs="4" sm="4" lg="4">
                <!-- if mobile  -->
                <v-btn v-if="$vuetify.breakpoint.xs" class="mb-10" large color="#fff"
                  style=" width: 150px; color: #fe5e02 !important; font-weight: 700; text-transform: capitalize; border-radius: 20px;"
                  to="/signin">
                  {{ $t('landing.getStarted') }}
                  <!-- Sign-in/Sign-up -->
                </v-btn>
              </v-col>

              <!-- if button is large screen -->
              <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
                <v-btn large color="#ffffff"
                  style="width: 150px; background-color: #fe5e02 !important; font-weight: 700; text-transform: capitalize; border-radius: 20px;"
                  to="/signin">
                  {{ $t('landing.getStarted') }}
                  <!-- Sign-in/Sign-up -->
                </v-btn><br />

                <span style="font-size:15px">Or Login with</span><br />

                <v-avatar size="40" @click="$router.push('/signup')">
                  <v-img
                    src="https://e7.pngegg.com/pngimages/337/722/png-clipart-google-search-google-account-google-s-google-play-google-company-text-thumbnail.png"></v-img>
                  <!-- Optionally, add content inside the avatar -->
                  <!-- You could add initials or something else here -->
                </v-avatar>

                <v-avatar size="40" style="margin-left:10px" @click="$router.push('/signup')">
                  <v-img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/2048px-MetaMask_Fox.svg.png"></v-img>
                  <!-- Optionally, add content inside the avatar -->
                  <!-- You could add initials or something else here -->
                </v-avatar>
              </v-col>
            </v-row>
          </div>
        </h1>

        <v-avatar size="70"
          src="https://e7.pngegg.com/pngimages/337/722/png-clipart-google-search-google-account-google-s-google-play-google-company-text-thumbnail.png">
          <!-- Optionally, add content inside the avatar -->
          <!-- You could add initials or something else here -->
        </v-avatar>
      </div>
      <div style="padding-right:20px;padding-left:20px">
        <!-- <v-img max-width="500" :src="require('@/assets/images/mobile.svg')" /> -->
        <v-card flat color="#1e2329" width="450" class="pa-4" style="padding:20px;border-radius: 8px;height:350px; margin-left:100px" :class="{
          'mx-2': $vuetify.breakpoint.xs,
         
        }">
          <!-- Card content here -->
          <v-row>
            <v-col cols="4">
              <span style="color:grey; font-weight: 600; position: relative; display: inline-block;">
                Popular
                <span
                  style="font-weight: 600; position: absolute; bottom: 0; left: 25%; width: 30%; border-bottom: 2px solid #fe5e02;"></span>
              </span>
            </v-col>
            <v-col cols="8" class="text-right">
              <span style="color:grey;font-weight:600" @click="$router.push('/markets/prices')">
                View More
              </span>
            </v-col>
          </v-row>
          <v-card-text style="padding:20px;max-height: 270px; overflow-y: auto; overflow:hidden">
            <MainCoin />
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-col cols="12" class="custom-margin" v-if="!$vuetify.breakpoint.xs">
      <main-home-download-app />
    </v-col>

    <v-col cols="12">

      <div class="text-center" :style="!$vuetify.breakpoint.xs && 'padding: 120px 8px;'">
        <h1 style="color: #fff;" :style="{
          fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
          margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
          maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
          textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
        }">
          {{ $t('landing.phrase_2') }}
        </h1>
      </div>

      <div class="py-10 text-center">
        <div v-if="!$vuetify.breakpoint.xs">
          <video class="third-pc-animation" autoplay="" loop="" playsinline="" width="936" height="253" muted=""
            style="visibility: visible;" src="https://www.okx.com/cdn/assets/files/2210/D47D930F643E7A00.webm"
            poster="https://www.okx.com/cdn/assets/imgs/2210/2763D233C494439D.jpg?x-oss-process=image/format,webp">
            <track kind="captions">
          </video>
        </div>

        <div v-else>
          <img class="third-mobile-animation" alt="With you every step of the wayFrom your first crypto trade to your first NFT purchase, you’ll have us to guide you through the process. No stupid questions. 
                No sleepless nights. Have confidence in your crypto." width="400" height="400"
            src="https://www.okx.com/cdn/assets/imgs/2210/602389EA3A7E31BD.gif">
        </div>
      </div>
    </v-col>

    <!-- news-->
    <div class="custom-margin">
      <v-row justify="center" align="center">

        <v-col xs="12" sm="8" md="10">

          <div :style="$vuetify.breakpoint.lg && 'padding: 120px 8px;'">

            <h1 style="color: #fff;" :style="{
              fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
              margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
              maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
              textAlign: 'center'
            }">
              {{ $t('landing.newsHeader') }}
            </h1>

            <v-progress-circular v-if="isLoading" indeterminate color="primary" class="my-10" style="
            top: 50%;
            bottom: 50%;
            left: 50%;
            transform: translate(-50%, -50%);">
            </v-progress-circular>

            <div v-else class="my-10">
              <v-row class="fill-height" align="center" v-if="!$vuetify.breakpoint.xs" justify="center">
                <v-col cols="4" xs="12" sm="10" md="6" lg="4" v-for="{ id, url, imageurl, title, body } in posts"
                  :key="id">

                  <v-hover v-slot:default="{ hover }">
                    <v-card  :href="url" tag="a" target="_blank" :elevation="hover ? 12 : 2"
                      :class="{ 'on-hover': hover }" style="border:solid 2px; border-color:#1e2329;border-radius:20%">

                      <v-img height="300px" :src="imageurl" class="white--text align-end" :gradient="hover
                        ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)'
                        : 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)'
                        ">
                        <div class="d-flex align-center justify-space-between pa-3">
                          <v-container>

                            <div >
                              <center>
                                <span class="d-flex align-center justify-center" style="color: #fff; font-size: 22px; font-weight: 700; line-height: 32px;">{{ title
                                }}</span>
                              </center>
                              
                            </div>

                            <div>
                              <span class="mt-2"
                                style="font-size: 14px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                {{ body }}
                              </span>
                            </div>
                          </v-container>
                        </div>
                      </v-img>

                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>

          </div>

          <v-list-item v-if="$vuetify.breakpoint.xs" style="border-bottom: 1px solid #949ca3;"
            v-for="{ id, url, title, body } in posts" :key="id" :href="url" tag="a" target="_blank">
            <v-list-item-content>
              <v-list-item-title> <span style="font-weight: 700;"> {{ title }}</span> </v-list-item-title>
              <span class="mt-2"
                style="font-size: 14px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{ body }}</span>
            </v-list-item-content>
          </v-list-item>

        </v-col>
      </v-row>

    </div>

    <div class="custom-margin">
      <main-home-questions />
    </div>

  </v-container>
</template>

<script>
import axios from 'axios';
import TopCoins from './home/TopCoins.vue'
import CryptoJS from 'crypto-js';

import TextFieldComponent from '../ui/TextfieldComponent.vue'

import { fetchPowPairs } from '@/services/marketServices';
import vuetify from '@/plugins/vuetify';

import MainCoin from '../main/markets/Tabs/AllTabLanding.vue';

export default {
  components: {
    TopCoins,
    TextFieldComponent,
    MainCoin,
  },

  data() {
    return {
      // powPairs: [],
      isLoading: false,
      posts: [],
      startNumber: 8140681,
      endNumber: 8140701,
      duration: 1000,
    };
  },

  methods: {

    calculateDaysBetweenDates(startDate) {
    // Convert the dates to Date objects
    const end = new Date();
    
    const start = new Date(startDate);
    // Calculate the difference in milliseconds
    const timeDifference = end - start;
    
    // Convert the time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    
    return daysDifference;
},

    animateNumber(element, start, end, duration) {
      let range = end - start;
      let increment = range / (duration / 10);
      let current = start;
      let stepTime = Math.abs(Math.floor(duration / range));

      let timer = setInterval(() => {
        current += increment;
        element.innerText = Math.floor(current).toLocaleString(); // Format as a number
        if ((increment > 0 && current >= end) || (increment < 0 && current <= end)) {
          clearInterval(timer);
          element.innerText = end.toLocaleString(); // Ensure it ends exactly at the target
        }
      }, stepTime);
    },

    manageNumberAnimation(element, initialValue) {
      let currentValue = initialValue;

      // Increment by 12 every 30 seconds
      setInterval(() => {
        let newEndValue = currentValue + 12;
        this.animateNumber(element, currentValue, newEndValue, 1000);
        this.endNumber = newEndValue; // 1-second animation
        currentValue = newEndValue;
      }, 30000); // 30 seconds

      // Decrement by 300 every 1 hour
      setInterval(() => {
        let newEndValue = currentValue - 300;
        this.animateNumber(element, currentValue, newEndValue, 1000); // 1-second animation
        currentValue = newEndValue;
      }, 3600000); // 1 hour (3600000 ms)
    },


    fetchNewsFeed() {
      this.isLoading = true
      axios.get('https://api.ubcoins.co/api/v3/news')
        .then(response => {
          try {

            this.posts = response.data.newsfeed.Data.slice(0, 6).map(child => ({
              id: child.id,
              title: child.title,
              url: child.url,
              body: child.body,
              imageurl: child.imageurl
            }));

            console.log(this.posts)

          } catch (error) {
            console.log(error)
          } finally {
            this.isLoading = false
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  },

  mounted() {
    // this.pow()
    const startDate = "2024-11-12"

    this.endNumber = this.endNumber + ((this.calculateDaysBetweenDates(startDate) * 24) * 120) * 12;
    this.endNumber = Math.floor(this.endNumber);
    this.fetchNewsFeed()
    this.manageNumberAnimation(document.getElementById("animated-number"),this.endNumber);
    this.animateNumber(document.getElementById("animated-number"), this.startNumber, this.endNumber, this.duration);
  },

  created() {
    console.log(this.powPairs)
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  //Handle beforeRouteEnter with route hash
  beforeRouteEnter(to, from, next) {
    if (to.hash) {
      next((vm) => {
        vm.$nextTick(() => {
          const el = document.getElementById(to.hash.replace("#", ""));
          if (el) {
            //The top of the element will be aligned to the top of the viewport
            // const top = el.offsetTop;
            // console.log(top);
            // window.scrollTo({ top: top - 300, behavior: "smooth" });
            // el.scrollIntoView({ behavior: "smooth", block: "start" });
            //Scroll to the most bottom of the page
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }
        });
      });
    } else {
      next();
    }
  },

};
</script>

<style scoped>
.v-slide-group__prev {
  display: none !important;
}

a {
  color: rgb(209, 209, 209) !important;
}

.fontSize {
  font-size: 3em;
}

.hero-section {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  /* height: 80vh; */
}

.full-height {
  height: 500px;
}

.custom-margin {
  margin-top: 100px;
}

/* #phones {
    max-height: 500px; 
  } */
</style>
