<template>
  <v-row justify="center">

    <v-col v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.md" xs="12" sm="8" md="6"
      lg="5" style="background-color: #2e3f49; height: 100vh;">
      <v-container>
        <div class=" d-flex flex-column align-center justify-center">
          <div class="banner">
            <div class="title-box" style="color: #fff">
              <h1>{{ $t('signin.phrase1') }} </h1>
              <p class="banner-desc">{{ $t('signin.phrase2') }}</p>
            </div>
          </div>

          <div class="banner-login">
            <v-img
              src="https://www.okx.com/cdn/assets/imgs/243/5AD5609D76BF42F4.png?x-oss-process=image/format,webp/resize,w_600,h_680"></v-img>
          </div>

          <div class="pa-4 d-flex flex-column justify-center" style="color: #fafafa; background-color:  rgba(228, 236, 255, .1);  border-radius: 10px; width: 450px;
          box-sizing: border-box;">
            <p class="title" style=" color: #fff;font-size: 16px;  font-weight: 600; line-height: 20px;"><span>
                {{ $t('signin.join') }}
              </span>
            </p>
            <p style=" color: hsla(0,0%,100%,.6);  font-size: 12px;   font-weight: 400;  line-height: 16px;   margin-top: 5px;"
              class="description">
              {{ $t('signin.phrase3') }}
            </p>
          </div>

        </div>
      </v-container>
    </v-col>

    <v-col xs="12" sm="8" md="8" lg="7" style="background-color: #fff; height: 100vh;">

      <v-card color="#00000000" v-if="step === 1" class="elevation-0">
        <v-row justify="center">
          <v-col cols="12" xs="12" sm="10" md="8" lg="6">
            <div :style="!$vuetify.breakpoint.xs && 'padding: 120px 8px;'">
              <h1 class="mb-10" style="color: #000;" :style="{
                fontSize: !$vuetify.breakpoint.xs ? '40px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
                margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
                maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '700',
                textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
              }">
                {{ $t('signin.login') }}
                <!-- <span style="color:#fe5e02;"> CryptoCurrency </span>
            Partner -->
                <br />
                <!-- On <span style="color:#f7a600">UPBIT</span> -->
              </h1>

              <v-form ref="loginForm" @submit="verifyEmail('dialog')" onSubmit="return false;">
                <!-- <label style="margin-top:10px;margin-bottom:10px">Email</label> -->
                <v-row align="center">
                  <!-- <v-col cols="4">
                  <v-select
                      v-model="selectedCountryCode"
                      :items="countryCodes"
                      item-text="dial_code"
                      item-value="dial_code"
                      required
                      hide-details
                  ></v-select>
              </v-col> -->
                  <v-col cols="12">

                    <div class="mb-5">  
                      <v-slide-group light mandatory v-model="selectedOption" active-class="#5E6673">
                        <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                          <v-btn small @input="loginOptions(value)" text class="" :input-value="active" @click="toggle">
                            {{ option }}
                          </v-btn>
                        </v-slide-item>
                      </v-slide-group>
                    </div>

                    <!-- Email Login -->
                    <div v-if="selectedOption === 0">
                      <label for="" style="color: #000; font-size: 14px; font-weight: 500;">
                        {{ $t('signin.email') }}
                      </label>

                      <v-text-field light color="#000000" class="mt-2" dense v-model="email" outlined
                        placeholder="Enter your email" required :rules="rules.emailRules">
                      </v-text-field>

                    </div>

                    <!-- Phone Login -->
                    <div v-if="selectedOption === 1">
                      <label light for="" style="color: #000; font-size: 14px; font-weight: 500;">
                        {{ $t('signin.phone') }}
                      </label>
                      <vue-tel-input-vuetify light color="#000000" class="mt-2" label="" :defaultCountry="'US'" dense
                        outlined v-model="mobile_number" placeholder="Enter your mobile" :maxLen=12
                        :rules="rules.phoneNumberRule" mode="international"></vue-tel-input-vuetify>
                    </div>
                  </v-col>
                </v-row>

                <v-btn light class="mt-1" :disabled="!email.length && !mobile_number" color="#000000"
                  style="font-size: 16px; color: #fff; border-radius: 20px;" large block @click="verifyEmail()">
                  {{ $t('signin.next') }}
                </v-btn>

                <div class="mt-10 text-center"> <!-- Add text-center class to center the content -->
                  <span style="font-size: 14px; color: #3D3D3D;">
                    {{ $t('signin.dontHave') }} <a @click="goTo('/signup')" class="signup-link">
                      <strong>{{ $t('signin.signup') }}</strong> </a>
                  </span>
                </div>
              </v-form>

              <div class=" d-flex justify-center align-center">
                <v-divider color="#E9EBF2" />
                <span class="text-center mx-2" style="font-size: 12px; color: #6E6E6E;"> {{ $t('signin.continue')
                }}</span>
                <v-divider color="#E9EBF2" />
              </div>

              <div class="d-flex justify-center">
                <div>
                  <v-btn @click="emailLogin()" small class="mt-6" outlined style="border: 1px solid #929292;" fab>
                    <v-avatar size="18">
                      <v-img :src="require('../assets/images/google.png')"></v-img>
                    </v-avatar>
                  </v-btn>
                </div>

                <div>
                  <v-btn @click="connectWallet('metamask')" small class="mt-6 ml-5" style="border: 1px solid #929292;"
                    outlined fab>
                    <v-avatar size="18">
                      <v-img :src="require('../assets/images/metamask-seeklogo.svg')"></v-img>
                    </v-avatar>
                  </v-btn>
                </div>

              </div>
            </div>

          </v-col>

        </v-row>
        <!-- <div class="mt-3 d-flex flex-column align-start">
            <span>Connect with</span>
            <div class="mt-2">
              <v-btn outlined @click="connectWallet('metamask')"> MetaMask</v-btn>
              <v-btn outlined class="ml-3" @click="connectWallet('trustwallet')">TrustWallet</v-btn>
            </div>
          </div> -->
      </v-card>

      <v-card color="#00000000" v-else transition="slide-x-reverse-transition"
        class="card-position elevation-0 text-center">

        <v-row justify="center">
          <v-col cols="12" xs="12" sm="10" md="8" lg="6">

            <div :style="!$vuetify.breakpoint.xs && 'padding: 120px 8px;'">
              <v-form ref="loginForm2" @submit="login('dialog')" onSubmit="return false;">

                <div>
                  <div class="mb-5" style="font-size: 24px; color: #000">{{ $t('signin.welcome') }}</div>
                  <!-- <span style="font-size: 16px; color: #fafafa;">{{ hiddenEmail }}</span> -->
                </div>

                <!-- <label style="margin-top:10px;margin-bottom:10px">Password</label> -->
                <v-row>
                  <v-col cols="12">
                    <div class="d-flex flex-column ">

                      <div class="d-flex align-center justify-space-between mb-2 ">
                        <label for="" style="color: #000; font-size: 14px; font-weight: 500;">
                          {{ $t('signin.password') }}
                        </label>

                      <span style="font-size: 14px; color: #3D3D3D;">
                          <a style="text-decoration: none;" class="signup-link" @click="checkEmail()"> <strong> {{
                            $t('signin.forgotPassword') }}</strong>
                          </a>
                        </span>
                      </div>

                      <v-text-field light dense color="#000000" v-model="password" outlined placeholder="Enter password"
                        :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
                        :rules="rules.passwordRules" :type="visible ? 'text' : 'password'"
                        @keydown.enter="login()"></v-text-field>

                    </div>

                    <!-- <v-text-field v-model="password" outlined placeholder="Type your password"
                      prepend-inner-icon="mdi-lock" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="visible = !visible" :rules="rules.passwordRules"
                      :type="visible ? 'text' : 'password'" @keydown.enter="login()"></v-text-field> -->
                  </v-col>
                </v-row>

                <v-btn light class="mt-1" color="#000000" style="font-size: 16px;  color: #fff; border-radius: 20px;"
                  large block @click="login(), (loader = 'loading')" :loading="loading" :disabled="loading || !password">
                  {{ $t('signin.signin') }}
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template></v-btn>

                <!-- <v-btn style="color: #1C1D28;" color="primary mb-3" class="mt-1" large block
                  @click="login(), (loader = 'loading')" :loading="loading" :disabled="loading">
                  Sign-in
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn> -->

                <!-- Forgot Password -->

                <!-- <span class="mt-3">
              <a><strong style="color;"></strong></a>
            </span> -->
                <!-- <v-btn text class="mt-6 font-weight-bold" color="primary" block>Forgot Password?</v-btn> -->
              </v-form>
            </div>

          </v-col>
        </v-row>

      </v-card>
      <v-dialog light v-model="verifyDialog" width="500">
        <v-card>
          <v-card-title>
            Verify Email
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="code" placeholder="Email Verification Code">
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="Proceed()" color="black" style="color:white">Next</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog light v-model="newPasswordDialog" width="500">
        <v-card>
          <v-card-title>
            New Password
          </v-card-title>
          <v-card-text>
            <v-text-field light color="#000000" class="mt-2" outlined dense v-model="new_password"
                      placeholder="New Password" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="visible = !visible" :rules="rules.passwordRules"
                      :type="visible ? 'text' : 'password'"></v-text-field>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" style="color:white" @click="changePassword()">Change Password</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import { login, emailLogin } from '../services/AuthServices.js'
import axios from 'axios'
import Web3 from 'web3';
export default {
  data() {
    return {
      verifyDialog: false,
      newPasswordDialog: false,
      metaMaskAccount: null,
      loading: false,
      step: 1,
      visible: false,
      email: "",
      phoneNumber: "",
      mobile_number: null,
      password: "",
      selectedCountryCode: "+63",
      phone: "",
      options: ['Email', 'Phone'],
      selectedOption: 0,
      
      rules: {
        //Form Field Rules
        phoneNumberRule: [
          (v) =>
            // v.startsWith(this.selectedCountryCode) ||
            (v) => v.length === 10 || "Please enter a valid phone number",
        ],

        atLeastTenCharsRule: [
          (v) => (v && v.length >= 10) || 'Phone number must have at least 10 characters',
        ],

        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        passwordRule: [
          (v) => v.length >= 6 || "Password must be at least 6 characters",
        ],
      },

      web3: null,
      accounts: null
    };
  },
  computed: {
    hiddenEmail() {
      if (this.selectedOption == 1) {
        var parts = this.email.split("@");
        var username = parts[0];
        var domain = parts[1];
        var first = username.charAt(0);
        var last = username.charAt(username.length - 1);
        var hiddenUsername =
          first +
          Array(username.length - 2)
            .fill("*")
            .join("") +
          last;
        return hiddenUsername + "@" + domain;
      }
      else {
        return this.mobile_number
      }

    },
  },

  mounted() {

  },

  watch: {
    mobile_number(newValue, oldValue) {
      console.log('New mobile number:', newValue);
    }
  },

  methods: {

    checkEmail()
    {
      axios.get('https://api.ubcoins.co/api/v3/forgot-password/send-code', 
        {params: {
          email:this.email
        }}
      ).then(response => {
        this.verifyDialog = true;
      })
      
    },

    Proceed()
    {
      axios.get('https://api.ubcoins.co/api/v3/forget-password-code/verify',{ params: {
          email: this.email,
          code: this.code
        }}).then(response => {
        if(response.data.message == 'success')
        {
          this.verifyDialog = false
          this.newPasswordDialog = true
        }
        else
        {
          this.$swal({
                  title: "Error!",
                  text: "Code do not Match",
                  icon: "error",
                  confirmButtonText: "OK",
                });
        }
        
      })
    },

    changePassword()
    {
      axios.get('https://api.ubcoins.co/api/v3/reset-password', {params: {
        email:this.email,
        password: this.new_password
      }}).then(response => {
        this.$swal({
                  title: "Success",
                  text: "Password successfully changed",
                  icon: "success",
                  confirmButtonText: "OK",
                });

        this.newPasswordDialog = false
      })
    },
    logMobileNumber() {
      console.log(this.mobile_number);
    },

    initiateOAuth() {
      // Redirect user to Laravel backend's OAuth provider's authorization endpoint
      window.location.href = 'https://api.ubcoins.co/api/v3/auth/google';
    },
    goTo(path) {
      this.$router.push(path);
    },

    normalizePhoneNumber() {
      // Remove extra spaces and '+63' from the mobile number
      return this.mobile_number.replace(/[\s]/g, '');
      return normalizedNumber.toString();
    },

    verifyEmail() {
      // Do something with the phone number
      if (this.$refs.loginForm.validate()) {
        if (this.selectedOption === 1) {

          const normalizedPhoneNumber = this.normalizePhoneNumber();

          this.$axios
            .get("https://api.ubcoins.co/api/v2/mobile-check", {

              params: {
                username: normalizedPhoneNumber,
              },
            })
            .then((response) => {
              if (response.data.message == "exist") {
                this.step = 2;
              } else {
                this.$swal({
                  title: "Error!",
                  text: "Email not found",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            });
        }
        else if (this.selectedOption === 0) {
          this.$axios
            .get("https://api.ubcoins.co/api/v2/username-check", {
              params: {
                username: this.email,
              },
            })
            .then((response) => {
              if (response.data.message == "exist") {
                this.step = 2;
              } else {
                this.$swal({
                  title: "Error!",
                  text: "Email not found",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            });
        }

      }
    },
    login() {
      if (this.$refs.loginForm2.validate()) {
        if (this.selectedOption === 1) {
          this.loading = true;
          this.$axios
            .post("https://api.ubcoins.co/api/v3/mobile-login", {
              username: this.mobile_number,
              password: this.password,
              mobile: this.mobile,
            })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.client.status == "FREEZE_ACCOUNT") {
                  this.loading = false;
                  this.$swal({
                    title: "Freezed Account!",
                    html: "Your account has been freezed. Please contact <a class='primary-text' href='https://direct.lc.chat/18298131/' target='_blank'>support</a> for more details.",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                } else {
                  this.$store.dispatch("setMarketCoins");
                  this.$store.commit("setToken", response.data.token);
                  this.$store.commit("setUser", response.data.user);
                  this.$store.commit("setClient", response.data.client);
                  this.$store.dispatch("setWallets");
                  this.$store.dispatch("fetchClientUser");
                  //Set 1.5 seconds timeout to redirect to dashboard
                  setTimeout(() => {
                    // this.$swal({
                    //   toast: true,
                    //   position: "top-end",
                    //   title: "Login Success!",
                    //   icon: "success",
                    //   showConfirmButton: false,
                    //   timer: 3000, // milliseconds
                    // });

                    if (this.$route.path !== '/wallet') {
                      // Using Vue Router to navigate to '/wallet' route
                      this.$router.push("/wallet");
                    }
                    this.loading = false;
                  }, 1500);
                }
              } else {
                this.loading = false;
                this.$swal({
                  title: "Error!",
                  text: "Login Failed",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.$swal({
                title: "Error!",
                text: error.response.data,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        }
        else if (this.selectedOption === 0) {
          this.loading = true;
          this.$axios
            .post("https://api.ubcoins.co/api/v3/login", {
              username: this.email,
              password: this.password,
              mobile: this.mobile,
            })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.client.status == "FREEZE_ACCOUNT") {
                  this.loading = false;
                  this.$swal({
                    title: "Freezed Account!",
                    html: "Your account has been freezed. Please contact <a class='primary-text' href='https://direct.lc.chat/18298131/' target='_blank'>support</a> for more details.",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                } else {
                  this.$store.dispatch("setMarketCoins");
                  this.$store.commit("setToken", response.data.token);
                  this.$store.commit("setUser", response.data.user);
                  this.$store.commit("setClient", response.data.client);
                  this.$store.dispatch("setWallets");
                  this.$store.dispatch("fetchClientUser");
                  //Set 1.5 seconds timeout to redirect to dashboard
                  setTimeout(() => {
                    if (this.$route.path !== '/wallet') {
                      // Using Vue Router to navigate to '/wallet' route
                      this.$router.push("/wallet");
                    }
                    this.loading = false;
                  }, 0);
                }
              } else {
                this.loading = false;
                this.$swal({
                  title: "Error!",
                  text: "Login Failed",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.$swal({
                title: "Error!",
                text: error.response.data,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        }

      }
    },

    mobileLogin() {
      if (this.$refs.loginForm2.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.ubcoins.co/api/v3/login", {
            username: this.username,
            password: this.password,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.client.status == "FREEZE_ACCOUNT") {
                this.loading = false;
                this.$swal({
                  title: "Freezed Account!",
                  html: "Your account has been freezed. Please contact <a class='primary-text' href='https://direct.lc.chat/18298131/' target='_blank'>support</a> for more details.",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              } else {
                this.$store.dispatch("setMarketCoins");
                this.$store.commit("setToken", response.data.token);
                this.$store.commit("setUser", response.data.user);
                this.$store.commit("setClient", response.data.client);
                this.$store.dispatch("setWallets");
                this.$store.dispatch("fetchClientUser");
                //Set 1.5 seconds timeout to redirect to dashboard
                setTimeout(() => {

                  if (this.$route.path !== '/wallet') {
                    // Using Vue Router to navigate to '/wallet' route
                    this.$router.push("/wallet");
                  }
                  this.loading = false;
                }, 0);
              }
            } else {
              this.loading = false;
              this.$swal({
                title: "Error!",
                text: "Login Failed",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$swal({
              title: "Error!",
              text: error.response.data,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    },

    emailLogin() {
      try {
        // Redirect user to OAuth provider for authentication
        window.location.href = 'https://api.ubcoins.co/api/v3/auth/google';
      } catch (error) {
        console.error('Error initiating OAuth:', error);
      }
    },
    async connectWallet(provider) {
      try {
        let web3;

        if (provider === 'metamask') {
          if (window.ethereum) {
            // Create a new Web3 instance
            web3 = new Web3(window.ethereum);

            // Request account access and retrieve accounts
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            // Get accounts after requesting access
            const loginAccount = await web3.eth.getAccounts();

            if (loginAccount.length > 0) {
              this.accounts = loginAccount;
              this.metaMaskAccount = loginAccount[0]; // Store the first account
              this.walletLogin()
            } else {
              console.warn('No MetaMask account found');
            }
          } else {
            console.error('MetaMask not found. Please install MetaMask.');
          }
        } else if (provider === 'trustwallet') {
          // Implementation for Trust Wallet connection
        } else {
          console.error('Unsupported wallet provider');
        }
      } catch (error) {
        console.error('Error connecting to wallet:', error);
      }
    },

    walletLogin() {
      this.$axios
        .post("https://api.ubcoins.co/api/v3/web3-login", {
          username: this.metaMaskAccount,

        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.client.status == "FREEZE_ACCOUNT") {
              this.loading = false;
              this.$swal({
                title: "Freezed Account!",
                html: "Your account has been freezed. Please contact <a class='primary-text' href='https://direct.lc.chat/18298131/' target='_blank'>support</a> for more details.",
                icon: "error",
                confirmButtonText: "OK",
              });
            } else {
              this.$store.dispatch("setMarketCoins");
              this.$store.commit("setToken", response.data.token);
              this.$store.commit("setUser", response.data.user);
              this.$store.commit("setClient", response.data.client);
              this.$store.dispatch("setWallets");
              this.$store.dispatch("fetchClientUser");
              //Set 1.5 seconds timeout to redirect to dashboard
              setTimeout(() => {

                if (this.$route.path !== '/wallet') {
                  // Using Vue Router to navigate to '/wallet' route
                  this.$router.push("/wallet");
                }
                this.loading = false;
              }, 0);
            }
          } else {
            this.loading = false;
            this.$swal({
              title: "Error!",
              text: "Login Failed",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$swal({
            title: "Error!",
            text: error.response.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  }
};
</script>

<style>
.signup-link {
  color: #1A1A1A !important;
  font-weight: 700;
}


.login {
  font-size: 28px;
  font-weight: 600;
  line-height: 132%;
  margin-bottom: 32px;
}

.banner {
  padding: 85px 100px;
}

.banner-login {
  height: 100%;
  justify-content: center;
  margin-bottom: 20px;
  width: 20.83333vw;
}

.signin-container {
  height: 90vh;
  background-image: url("https://www.bybit.com/common-static/cht-static/user-svc/img/loginPic.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background: linear-gradient(to bottom right, #13617c, #2e2e2e); */
  color: white;
  /* Optionally, set text color to make it visible against the background */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Button Spinner */
/* .custom-loader {
  animation: loader 1s infinite;
  display: flex;
} */

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
